.auth-form {
  max-width: 360px;
  margin: 60px auto;
  padding: 40px;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  background: #fff;
}

.login {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login p {
    margin-top: 20px;
    font-size: 13px;
}