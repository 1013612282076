.home {
  color: #383737;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
}
.home h1 {
  text-align: center;
  padding: 0 0.7rem;
}
h2 {
  text-align: left;
  padding: 0 0.7rem;
}
h3 {
  text-align: left;
  padding: 0 0.7rem;
}
h4 {
  text-align: center;
  padding: 0 0.7rem;
}
p {
  text-align: left;
  padding: 1rem 1rem 0 1rem;
  max-width: 1000px;
}

.home-img {
  width: 200px;
  animation: upDownAnimation 2s ease-in-out infinite;
}

@keyframes upDownAnimation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@media (max-width: 930px) {
  .home {
    padding-top: 7rem;
  }
}

@media (max-width: 300px) {
  .home h1 {
    font-size: 27px;
  }
}
