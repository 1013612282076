.avatar {
  display: inline-block;
  width: 50px;
  height: 50px;
  overflow: hidden;
}
.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
