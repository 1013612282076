.navbar {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  margin-bottom: 80px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}

.navbar .logo {
  color: #d9d2c3;
  font-size: 28px;
  font-weight: 600;
}
.navbar .logo img {
  margin-right: 10px;
  width: 45px;
}

.navbar .links {
  list-style: none;
  display: flex;
  color: #d9d2c3;
  cursor: pointer;
}

.navbar .links li {
  margin: 0 15px;
  font-weight: 600;
}

a {
  color: #d9d2c3;
  text-decoration: none;
}

.navbar .toggle-icon {
  display: none;
}

@media (max-width: 930px) {
  .navbar {
    position: fixed;
    transition: 0.5s all ease-in-out;
    background-color: #0077ff;
    width: 100%;
    padding: 40px;
    flex-direction: column-reverse;
    z-index: 100;
  }

  .navbar.expanded {
    height: 100vh;
  }

  .navbar.expanded .links {
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }

  .navbar.expanded .right {
    position: absolute;
    top: 400px;
    left: 0;
    width: 100%;
    opacity: 1;
  }

  .navbar.expanded .links li {
    margin: 15px 0;
    font-size: 24px;
  }

  .navbar .logo {
    position: absolute;
    top: 23px;
    left: 20px;
  }

  .navbar .toggle-icon {
    display: block;
    color: #d9d2c3;
    position: absolute;
    top: 28px;
    right: 20px;
  }

  .navbar .links,
  .navbar .right {
    position: absolute;
    top: -300px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }

  .navbar .links li {
    margin: 15px 0;
    font-size: 24px;
  }
}

@media (max-width: 450px) {
  .navbar .logo span {
    display: none;
  }
}
