.App {
  display: flex;
  max-width: 3500px;
  margin: 0 auto;
  background-image: url("./assets/bg.png");
  background-repeat: no-repeat;
  min-height: 100vh;
  background-size: cover;
  /* background-position: center; */
}
.App .container {
  flex-grow: 1;
}
